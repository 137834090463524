import logo from '../img/mploy.png';
import bg from '../img/bg.jpg';

import { useState } from "react";
import { ToastContainer, toast } from 'react-toast';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PostApi from '../Services/PostApi';
import BeatLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

function Login() {
  const [status, setstatus] = useState(false);
  const [flag, setflag] = useState(true);
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const firebaseConfig = {
    apiKey: "AIzaSyBTDiJn7m1p1rQjwZU9h4oP0kTbeR2FHXE",
    authDomain: "mploy-739d0.firebaseapp.com",
    projectId: "mploy-739d0",
    storageBucket: "mploy-739d0.appspot.com",
    messagingSenderId: "170671328236",
    appId: "1:170671328236:web:11858b513eb7e4efaecec9",
    measurementId: "G-LEYKX52LBD"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.languageCode = 'en';
  const recaptchaVerifier = () => {
    //window.recaptchaVerifier.recaptcha.reset();
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => { }
    }, auth);
  }

  const requestOtp = async () => {
    setLoading(true);
    if (flag) {
      recaptchaVerifier();
      setflag(false);
    }

    if (phoneNumber.length === 10) {
      // reCAPTCHA solved, allow signInWithPhoneNumber.       
      signInWithPhoneNumber(auth, '+91' + phoneNumber, window.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          setstatus(true);
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          toast.error(error.message ? error.message : 'Something went wrong. Please try again!!');
        })
    } else {
      toast.error("Please enter a valid phone number");
      setLoading(false);
    }
  }

  const verifyOTP = () => {
    setLoading(true);
    if (otp.length === 6) {
      window.confirmationResult.confirm(otp).then((result) => {
        sessionStorage.setItem('login', true);
        let tableData = { "list_key": "Mastertable", "label": "eks_form", "select": "*", "condition": { "status": 1, "eks_form_mobile": phoneNumber } }
        PostApi('services.php', tableData).then((e) => {
          sessionStorage.setItem('user', JSON.stringify(e.responcePostData.data.result));
          !e.responcePostData.data.result.length ?
            PostApi('services.php', { "list_key": "AddMaster", "label": "eks_form", "tablefields": { "eks_form_mobile": phoneNumber } }).then((e) => {
              PostApi('services.php', tableData).then((e) => {
                sessionStorage.setItem('user', JSON.stringify(e.responcePostData.data.result));
                navigate('/forms');
                setLoading(false);
              })
            }) : navigate('/forms');
          setLoading(false);
        })

      }).catch((error) => {
        toast.error(error.message ? error.message : 'Something went wrong. Please try again!!');
        setLoading(false);
      })
    } else {
      toast.error("Please enter a valid OTP");
      setLoading(false);
    }
  }
  return (
    <div className="relative flex flex-col px-4 py-16 mx-auto lg:block lg:flex-col lg:py-18 xl:py-20 md:px-8 sm:max-w-xl md:max-w-full">
      <ToastContainer position='top-right' />
      <div className="z-0 flex justify-center h-full -mx-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-0 lg:left-0 lg:items-center">
          <img loading="lazy" 
          src={bg}
          className=" h-60 lg:h-auto lg:w-auto  hidden md:block"
          alt=""
        />
      </div>
      <div className="relative flex justify-end max-w-xl mx-auto xl:pr-32 lg:max-w-screen-xl">
        <div className="mb-16 lg:pr-5 lg:max-w-lg lg:mb-0">
          <div className=" text-center">
              <img loading="lazy"
              src={logo}
              className="h-24 w-auto text-center md:h-36"
              alt=""
            /></div>
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                Register / Sign IN
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              First Step to your
              <br className="block" />
              {' '}Dream{' '}
              <span className="inline-block text-blue-600 mt-1">
                Job
              </span>
            </h2>
            <p className="text-md italic font-semibold text-gray-900 dark:text-white">
              "Choose a job you love, and you will never have to work a day in your life."
            </p>
          </div>
          <form>
            {!status ? <><div className="flex flex-col md:flex-row">
              <input
                placeholder="Phone Number"
                required=""
                type="number"
                onChange={(e) => setPhoneNumber(e.target.value)} onWheel={(e) => e.target.blur()}
                className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />

            </div>
              <div className="flex items-center mt-4">
                <button
                  disabled={loading} onClick={(e) => { requestOtp() }}
                  className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition 
                duration-200 rounded shadow-md bg-blue-600 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none min-w-fit"
                >
                  {!loading ? 'Continue' : <BeatLoader size={15} color="#fff" />}
                </button>
              </div> </> :
              <>
                <div className="flex flex-col md:flex-col">
                  <input type="number" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} onWheel={(e) => e.target.blur()}
                    placeholder="OTP"
                    className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline" />
                  <p className='text-xs text-black text-center mt-2'>Dont receivethe OTP? <b onClick={() => requestOtp()}> RESEND OTP</b> </p>
                  <div className="flex flex-row justify-between items-start gap-2">
                    <button type="button" onClick={() => { setstatus(false); }} className="p-4 my-2 w-full text-black border border-red-600  text-red  font-medium  text-xs  leading-tight  uppercase  shadow-md  hover:bg-red-700 hover:shadow-lg  focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0  active:bg-red-800 active:shadow-lg  transition  duration-150  ease-in-out">
                      Cancel
                    </button>
                    <button type="button" onClick={() => verifyOTP()} className="p-4 my-2 w-full border  bg-blue-600  text-white  font-medium  text-xs  leading-tight  uppercase  shadow-md  hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0  active:bg-blue-800 active:shadow-lg  transition  duration-150  ease-in-out">
                      {!loading ? 'Verify' : <BeatLoader size={10} color="#ffffff" />}
                    </button>
                  </div>
                </div>
              </>}
          </form>
        </div>
      </div>
      <div id='sign-in-button' className='hidden'></div>
    </div>

  );
}

export default Login;
